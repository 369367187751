import { render, staticRenderFns } from "./etiqueta.vue?vue&type=template&id=89f472d8&scoped=true"
import script from "./etiqueta.vue?vue&type=script&lang=js"
export * from "./etiqueta.vue?vue&type=script&lang=js"
import style0 from "./etiqueta.vue?vue&type=style&index=0&id=89f472d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89f472d8",
  null
  
)

export default component.exports